import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './core/routes/Routes';
import Page from './shared/components/Page';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';

import { Auth0ProviderConfig } from 'core/auth/auth0ProviderConfig';
import { ErrorProvider } from 'core/context/ErrorContext';
import ErrorModal from 'shared/components/ErrorModal';

const App = (): JSX.Element => {
  return (
    <Page>
      <ErrorProvider>
        <BrowserRouter>
          <Auth0ProviderConfig>
            <Routes />
          </Auth0ProviderConfig>
        </BrowserRouter>
        <ErrorModal />
      </ErrorProvider>
    </Page>
  );
};

export default App;
