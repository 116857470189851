import React from 'react';
import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

interface LoadingProps {
  message: string;
  height?;
}

export const Loading = ({ message, height }: LoadingProps): JSX.Element => {
  return (
    <Box sx={{ height: height ? height : '100px' }}>
      <LinearProgress
        color="secondary"
        sx={{
          zIndex: 3,
          height: '2px',
        }}
      />
      <Typography variant="subtitle1" ml={2} mt={2}>
        {message ? message : ''}
      </Typography>
    </Box>
  );
};
