import { useState } from 'react';

export function useWebsocketConnection() {
  // Initialize websocket connection data from localStorage
  const getInitialWebsocketConnection = () => {
    const storedWebsocketConnection = localStorage.getItem(
      'websocketConnection',
    );
    return storedWebsocketConnection
      ? JSON.parse(storedWebsocketConnection)
      : null;
  };

  const [websocketConnection, setWebsocketConnection] = useState(
    getInitialWebsocketConnection(),
  );

  const updateWebsocketConnection = (newData) => {
    localStorage.removeItem('websocketConnection'); // Clear old connection data
    localStorage.setItem('websocketConnection', JSON.stringify(newData));
    setWebsocketConnection(newData);
  };

  const removeWebsocketConnection = () => {
    localStorage.removeItem('websocketConnection');
    setWebsocketConnection(null); // Clear local state
  };

  return [
    websocketConnection,
    updateWebsocketConnection,
    removeWebsocketConnection,
  ];
}
