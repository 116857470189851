import { useState } from 'react';

export function useOrgSetupData() {
  // Initialize org setup data from localStorage
  const getInitialOrgSetupData = () => {
    const storedOrgSetupData = localStorage.getItem('orgSetupData');
    return storedOrgSetupData ? JSON.parse(storedOrgSetupData) : null;
  };

  const [orgSetupData, setOrgSetupData] = useState(getInitialOrgSetupData());

  const updateOrgSetupData = (newData) => {
    localStorage.removeItem('orgSetupData'); // Clear old setup data
    localStorage.setItem('orgSetupData', JSON.stringify(newData));
    setOrgSetupData(newData);
  };

  const removeOrgSetupData = () => {
    localStorage.removeItem('orgSetupData');
    setOrgSetupData(null); // Clear local state
  };

  return [orgSetupData, updateOrgSetupData, removeOrgSetupData];
}
