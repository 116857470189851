import React, { useContext } from 'react';
import { Modal, Box, Typography, Button, Alert } from '@mui/material';
import { ErrorContext } from 'core/context/ErrorContext';
import { alpha, useTheme } from '@mui/material/styles';

const ErrorModal: React.FC = () => {
  const { error, setError } = useContext(ErrorContext);
  const theme = useTheme();
  const handleClose = () => {
    setError(null);
  };

  return (
    <Modal open={!!error} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: alpha(theme.palette.alternate.main, 1),
          p: 4,
          borderRadius: 1,
          minWidth: 300,
          outline: 'none',
        }}
      >
        <Typography variant="h6">Error</Typography>
        <Alert severity="warning" sx={{ mt: 2 }}>
          {error?.message || 'An error occurred, Please try again later.'}
        </Alert>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
