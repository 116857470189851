import { PaletteMode } from '@mui/material';

export const light = {
  alternate: {
    main: '#f5faff',
    dark: '#edf1f7',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light' as PaletteMode,
  primary: {
    main: '#0984e3',
    light: '#0a8ff5',
    dark: '#0879d0',
    contrastText: '#fff',
  },
  secondary: {
    main: '#0984e3',
    light: '#0a8ff5',
    dark: '#0879d0',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#1e2022',
    secondary: '#677788',
    neutral: '#FFF',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f3f3f3',
    level1: '#ffffff',
    level3: '#f3f3f3',
  },
};

export const dark = {
  alternate: {
    main: '#0f161e',
    dark: '#000',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark' as PaletteMode,
  primary: {
    main: '#0984e3',
    light: '#0a8ff5',
    dark: '#0879d0',
    contrastText: '#fff',
  },
  secondary: {
    main: '#0984e3',
    light: '#0a8ff5',
    dark: '#0879d0',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
    neutral: '#FFF',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#091016',
    default: '#091016',
    level2: '#333',
    level1: '#2D3748',
    level3: '#f3f3f3',
  },
};
