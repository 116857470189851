export const appConstants = {
  ROLES: {
    SUPER: 'SUPER',
    VIEWER: 'VIEWER',
    OWNER: 'OWNER',
  },
  ROUTES: {
    SEARCH_SETUP: '/search-setup',
    SITES_PAGES: '/search-setup/site-pages',
    CATEGORY_MANAGER: '/search-setup/site-pages/category-manager',
    CONFIGURE_SEARCH: '/search-setup/configurations',
    TRY_MY_SEARCH: '/search-setup/try-my-search',
    ADD_CONFIGURATION: '/search-setup/configurations/add-configuration',
    TUTORIAL: '/search-setup/tutorial',
    EDIT_CONFIGURATION: '/search-setup/configurations/edit-configuration',
  },
  BREADCRUMBS: {
    SEARCH_SETUP: {
      MAIN: 'Search Setup',
      ITEMS: {
        SITES_PAGES: 'Sites and Pages',
        CATEGORY_MANAGER: 'Category Manager',
        ADD_SITE: 'Add Site',
        EDIT_SITE: 'Edit Site',
        CONFIGURE_SEARCH: 'Configure Search',
        CONFIGURE_NEW_SEARCH: 'Configure a new search',
        TRY_MY_SEARCH: 'Try My Search',
      },
    },
    DASHBOARD: {
      MAIN: 'Dashboard',
      ITEMS: {
        SEARCH_METRICS: 'Search Metrics',
        PLAN_METRICS: 'Plan Metrics',
      },
    },
  },
};
