import React from 'react';
import { createRoot } from 'react-dom/client'; // Imported from 'react-dom'
import App from './App';
import ReactGA from 'react-ga';
import './index.css';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// AppInsights Telemetry
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

ReactGA.initialize('G-WJLMLXWT98');

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
} else {
  console.error('Root element not found'); // Handle the error if the root element is not found
}
