import React, { ReactElement } from 'react';

type Guard = (props: { component: () => ReactElement }) => ReactElement;

export const withGuards = (
  component: JSX.Element,
  guards: Array<{ guard: Guard; params?: object }>,
): JSX.Element => {
  return guards
    .reverse()
    .reduce((wrappedComponent, { guard: Guard, params }) => {
      return <Guard component={() => wrappedComponent} {...params} />;
    }, component);
};
