import React from 'react';
import { Navigate, Routes as ReactRoutes, Route } from 'react-router-dom';
import { AuthenticationGuard } from 'core/guards/authentication-guard';
import { OrganizationAccessGuard } from 'core/guards/organization-access-guard';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from 'shared/components/LoadingComponent';
import { withGuards } from '../guards/withGuards';
import routeComponents from './routeComponents';
import { OrgCodeVerificationGuard } from 'core/guards/org-code-verification-guard';
import { OrgRoleGuard } from 'core/guards/org-role-guard';
import { appConstants } from 'const/appConstants.const';

const Routes = (): JSX.Element => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <Loading message="" />;
  }

  return (
    <ReactRoutes>
      {/* <Route path="/" element={<routeComponents.Home />} /> */}
      <Route path="/" element={<routeComponents.IndexView />} />
      <Route
        path="/contact-us"
        element={<routeComponents.ContactPageCover />}
      />
      <Route path="/support" element={<routeComponents.ContactPageCover />} />
      <Route path="/company-terms" element={<routeComponents.CompanyTerms />} />
      <Route
        path="dashboard/search-metrics"
        element={withGuards(<routeComponents.Dashboard />, [
          { guard: AuthenticationGuard },
          { guard: OrgCodeVerificationGuard },
          { guard: OrganizationAccessGuard, params: { verifyAll: true } },
        ])}
      />
      <Route
        path="dashboard/plan-metrics"
        element={withGuards(<routeComponents.UsageMetrics />, [
          { guard: AuthenticationGuard },
          { guard: OrgCodeVerificationGuard },
          { guard: OrganizationAccessGuard, params: { verifyAll: true } },
        ])}
      />
      <Route
        path={appConstants.ROUTES.CATEGORY_MANAGER}
        element={withGuards(<routeComponents.CategoryManager />, [
          { guard: AuthenticationGuard },
          { guard: OrgCodeVerificationGuard },
          { guard: OrganizationAccessGuard, params: { verifyAll: true } },
          {
            guard: OrgRoleGuard,
            params: {
              allowedRoles: [
                appConstants.ROLES.SUPER,
                appConstants.ROLES.OWNER,
              ],
            },
          },
        ])}
      />
      {/* <Route
        path="automation/searchIndex"
        element={withGuards(<routeComponents.Search />, [
          { guard: AuthenticationGuard },
          { guard: OrgCodeVerificationGuard },
          { guard: OrgRoleGuard, params: { allowedRoles: [appConstants.ROLES.SUPER, appConstants.ROLES.OWNER] } },
          { guard: OrganizationAccessGuard, params: { verifyAll: true } },
        ])}
      /> */}

      <Route
        path={appConstants.ROUTES.SEARCH_SETUP}
        element={<Navigate to={appConstants.ROUTES.SITES_PAGES} replace />}
      />
      <Route path={appConstants.ROUTES.SITES_PAGES}>
        <Route
          index
          element={withGuards(<routeComponents.SiteManager />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
        <Route
          path="add-site"
          element={withGuards(<routeComponents.AddCrawler />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
        <Route
          path="edit-site/:siteId"
          element={withGuards(<routeComponents.AddCrawler />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
        <Route
          path="add-page/:siteId"
          element={withGuards(<routeComponents.AddCrawler />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
        {/* <Route
          path="site/:siteId"
          element={withGuards(<routeComponents.SitePages />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
          ])}
        /> */}
      </Route>
      <Route path="/admin/team-manager">
        <Route
          index
          element={withGuards(<routeComponents.UserManagement />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
        <Route
          path="invite"
          element={withGuards(<routeComponents.AddUser />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
        <Route
          path="edit/:userId"
          element={withGuards(<routeComponents.EditUser />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
      </Route>
      <Route path="/registration">
        <Route
          index
          element={withGuards(<routeComponents.Registration />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: false } },
          ])}
        />
        <Route
          path="organization"
          element={withGuards(<routeComponents.Organization />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: false } },
          ])}
        />
        <Route
          path="code-verification/:verificationCode?"
          element={withGuards(<routeComponents.CodeVerification />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: false } },
          ])}
        />
        <Route
          path="subscription"
          element={withGuards(<routeComponents.Subscription />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
          ])}
        />
        <Route
          path="verify-email"
          element={withGuards(<routeComponents.VerifyEmail />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
          ])}
        />
        <Route
          path="user-profile"
          element={withGuards(<routeComponents.ManageProfile />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: false } },
          ])}
        />
        <Route
          path="new-user/:verificationCode"
          element={withGuards(<routeComponents.NewUserSignUpRedirect />, [])}
        />
      </Route>
      <Route path="/selectOrganization">
        <Route
          index
          element={withGuards(<routeComponents.SelectOrganization />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: false } },
          ])}
        />
        {/* Removed Org Guard for Select Organization since it keeps looping */}
      </Route>
      <Route
        path="/manage-profile"
        element={withGuards(<routeComponents.ManageProfile />, [
          { guard: AuthenticationGuard },
          { guard: OrgCodeVerificationGuard },
          { guard: OrganizationAccessGuard, params: { verifyAll: true } },
        ])}
      />
      {/* <Route path="/admin/subscription-manager">
        <Route
          index
          element={withGuards(<routeComponents.SubscriptionManager />, [
             { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
          ])}
        />
      </Route> */}
      <Route
        path="search-setup/tutorial/:apiKeyId"
        element={withGuards(<routeComponents.Tutorial />, [
          { guard: AuthenticationGuard },
          { guard: OrgCodeVerificationGuard },
          { guard: OrganizationAccessGuard, params: { verifyAll: true } },
          {
            guard: OrgRoleGuard,
            params: {
              allowedRoles: [
                appConstants.ROLES.SUPER,
                appConstants.ROLES.OWNER,
              ],
            },
          },
        ])}
      />
      <Route
        path="/search-setup"
        element={<Navigate to={appConstants.ROUTES.CONFIGURE_SEARCH} replace />}
      />
      <Route path={appConstants.ROUTES.CONFIGURE_SEARCH}>
        <Route
          index
          element={withGuards(<routeComponents.SearchManagement />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />

        <Route
          path="add-configuration"
          element={withGuards(<routeComponents.AddEditConfiguration />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
        <Route
          path="edit-configuration/:configurationId"
          element={withGuards(<routeComponents.AddEditConfiguration />, [
            { guard: AuthenticationGuard },
            { guard: OrgCodeVerificationGuard },
            { guard: OrganizationAccessGuard, params: { verifyAll: true } },
            {
              guard: OrgRoleGuard,
              params: {
                allowedRoles: [
                  appConstants.ROLES.SUPER,
                  appConstants.ROLES.OWNER,
                ],
              },
            },
          ])}
        />
      </Route>
      <Route
        path="search-setup/try-my-search/"
        element={withGuards(<routeComponents.TryMySearch />, [
          { guard: AuthenticationGuard },
          { guard: OrgCodeVerificationGuard },
          { guard: OrganizationAccessGuard, params: { verifyAll: true } },
        ])}
      />
      <Route
        path="search-setup/try-my-search/:apiKeyId"
        element={withGuards(<routeComponents.TryMySearch />, [
          { guard: AuthenticationGuard },
          { guard: OrgCodeVerificationGuard },
          { guard: OrganizationAccessGuard, params: { verifyAll: true } },
        ])}
      />
      <Route path="*" element={<routeComponents.NotFound />} />
    </ReactRoutes>
  );
};

export default Routes;
