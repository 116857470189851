import React, { createContext, useState } from 'react';

interface ErrorState {
  status?: number;
  message: string;
}

interface ErrorContextProps {
  error: ErrorState | null;
  setError: React.Dispatch<React.SetStateAction<ErrorState | null>>;
}

export const ErrorContext = createContext<ErrorContextProps>({
  error: null,
  setError: () => {},
});

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [error, setError] = useState<ErrorState | null>(null);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};
