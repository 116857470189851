import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { variables } from 'const/variables';

export const Auth0ProviderConfig = ({ children }) => {
  const {
    REACT_APP_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_REDIRECT_URI,
    REACT_APP_AUTH0_API_AUDIENCE,
  } = variables;
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (
    !(REACT_APP_DOMAIN && REACT_APP_AUTH0_CLIENT_ID && REACT_APP_REDIRECT_URI)
  ) {
    return null;
  }

  return (
    <Auth0Provider
      domain={REACT_APP_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: REACT_APP_REDIRECT_URI,
        scope: 'openid profile email phone',
        audience: REACT_APP_AUTH0_API_AUDIENCE,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={false}
    >
      {children}
    </Auth0Provider>
  );
};
