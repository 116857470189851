import React, { useEffect, useState, useCallback } from 'react';
import { useOrgRoleData } from 'core/state/useOrgRoleData';
import { useNavigate } from 'react-router-dom';

export const OrgRoleGuard = ({ component: Component, allowedRoles = [] }) => {
  const [orgRoleData] = useOrgRoleData();
  const [canProceed, setCanProceed] = useState(null);
  const navigate = useNavigate();

  const checkUserRole = useCallback(() => {
    if (!orgRoleData?.role?.name) {
      setCanProceed(false);
      return;
    }

    // Check if the user's role is one of the allowed roles
    if (allowedRoles.includes(orgRoleData.role.name)) {
      setCanProceed(true);
    } else {
      setCanProceed(false);
    }
  }, [orgRoleData, allowedRoles]);

  useEffect(() => {
    checkUserRole();
  }, [checkUserRole]);

  useEffect(() => {
    if (canProceed === false) {
      navigate('/unauthorized');
    }
  }, [canProceed, navigate]);

  if (canProceed === null) return null;
  if (canProceed) return <Component />;
  return null;
};
