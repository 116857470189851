import { useState } from 'react';

export function useUserData() {
  // Initialize user data from localStorage
  const getInitialUserData = () => {
    const storedUserData = localStorage.getItem('userData');
    return storedUserData ? JSON.parse(storedUserData) : null;
  };

  const [userData, setUserData] = useState(getInitialUserData());

  const updateUserData = (newData) => {
    localStorage.removeItem('userData'); // Clear old user data
    localStorage.setItem('userData', JSON.stringify(newData));
    setUserData(newData);
  };

  const removeUserData = () => {
    localStorage.removeItem('userData'); // Remove user data from localStorage
    // localStorage.removeItem('orgRoleData');
    setUserData(null); // Clear local state
  };

  return { userData, updateUserData, removeUserData };
}
