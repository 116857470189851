import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from 'shared/components/LoadingComponent';
import { useLocation } from 'react-router-dom';

export const AuthenticationGuard = ({ component: Component }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: location.pathname + location.search },
      });
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    location.pathname,
    location.search,
  ]);
  if (isLoading || !isAuthenticated) {
    return <Loading message="Verifying your credentials..." />;
  }

  return <Component />;
};
