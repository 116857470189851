/* eslint-disable no-undef */
export const variables = {
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_DOMAIN:
    localStorage.getItem('appDomain') || process.env.REACT_APP_DOMAIN,
  REACT_APP_AUTH0_API_AUDIENCE:
    localStorage.getItem('appAudience') || process.env.REACT_APP_AUTH0_API_AUDIENCE,
  REACT_APP_REDIRECT_URI: window.location.origin,
  REACT_APP_AUTH_API_POINT:
    localStorage.getItem('appAuthApiBase') ||
    process.env.REACT_APP_AUTH_API_POINT,
  REACT_APP_API_POINT:
    localStorage.getItem('appApiBase') || process.env.REACT_APP_API_POINT,
};
