import { useState } from 'react';

export function useOrgRoleData() {
  // Initialize org and role data from localStorage
  const getInitialOrgRoleData = () => {
    const storedOrgRoleData = localStorage.getItem('orgRoleData');
    return storedOrgRoleData ? JSON.parse(storedOrgRoleData) : null;
  };

  const [orgRoleData, setOrgRoleData] = useState(getInitialOrgRoleData());

  const updateOrgRoleData = (newData) => {
    localStorage.removeItem('orgRoleData'); // Clear old user data
    localStorage.setItem('orgRoleData', JSON.stringify(newData));
    setOrgRoleData(newData);
  };

  const removeOrgRoleData = () => {
    localStorage.removeItem('orgRoleData');
    setOrgRoleData(null); // Clear local state
  };

  return [orgRoleData, updateOrgRoleData, removeOrgRoleData];
}
