import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiConfig } from 'const/apiConfig.const';
import { useUserData } from 'core/state/useUserData';
import { useOrgRoleData } from 'core/state/useOrgRoleData';
import { useOrgSetupData } from 'core/state/useOrgSetupData';
import { useFetchWithAuth } from '../auth/fetchWithAuth';
import { Loading } from 'shared/components/LoadingComponent';
import { useWebsocketConnection } from 'core/state/useWebsocketConnection';

export const OrganizationAccessGuard = ({ component: Component, ...props }) => {
  const navigate = useNavigate();
  const { userData, updateUserData } = useUserData();
  const [orgRoleData, updateOrgRoleData, removeOrgRoleData] = useOrgRoleData();
  const [orgSetupData, updateOrgSetupData, removeOrgSetupData] =
    useOrgSetupData();
  const [
    websocketConnection,
    updateWebsocketConnection,
    removeWebsocketConnection,
  ] = useWebsocketConnection();
  const location = useLocation();
  const { getData } = useFetchWithAuth();
  const [hasAccess, setHasAccess] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const setAccessAndNavigate = (access, path) => {
    setHasAccess(access);
    if (!access) navigate(path);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let responseData = userData;

        // Fetch User Data if not available
        if (!userData || !orgRoleData) {
          setLoading(true);
          const response = await getData(apiConfig.api.user, { method: 'GET' });
          responseData = await response.json();
          updateUserData(responseData);
          setLoading(false);
        }

        // Fetch Org Setup Data
        if (orgRoleData && !orgSetupData) {
          const fetchSetupData = async () => {
            try {
              const response = await getData(apiConfig.api.orgSetupDetails, {
                method: 'GET',
              });
              const responseData = await response.json();
              updateOrgSetupData(responseData);
            } catch (e) {
              setLoading(false);
              setHasAccess(false);
              console.error(e);
            }
          };
          fetchSetupData();
        }

        // Fetch Websocket Connection Data if not available
        if (orgRoleData && !websocketConnection) {
          const fetchWebsocketConnectionData = async () => {
            try {
              const response = await getData(
                apiConfig.api.websocketConnectionUrl,
                {
                  method: 'GET',
                },
              );
              const responseData = await response.text();
              updateWebsocketConnection(responseData);
            } catch (e) {
              setLoading(false);
              setHasAccess(false);
              console.error(e);
            }
          };
          fetchWebsocketConnectionData();
        }

        // Always check if the email is verified, regardless of props.verifyAll
        if (!responseData?.auth0UserInfo?.email_verified) {
          setAccessAndNavigate(false, '/registration/verify-email');
          return;
        }

        // If props.verifyAll is false, after checking email verification, grant access and return
        if (!props.verifyAll) {
          setHasAccess(true);
          return;
        }

        // Check if the user first name and last name is available
        if (!responseData?.firstName || !responseData?.lastName) {
          setAccessAndNavigate(false, '/registration/user-profile');
          return;
        }

        // Is Not Same User
        if (orgRoleData && responseData.id !== orgRoleData?.id?.userId) {
          removeOrgRoleData();
          setAccessAndNavigate(false, '/selectOrganization');
          return;
        }

        const hasAtleastOneOrgAssociation =
          responseData?.userOrgRoles?.length > 0;
        const hasSubscription = orgRoleData?.org?.activeSubscription;

        // Has atleast 1 organization
        if (!hasAtleastOneOrgAssociation) {
          setAccessAndNavigate(false, '/registration');
          return;
        }
        // Has one org Association and the org has not been selected yet but has active subscription
        if (responseData?.userOrgRoles?.length === 1 && !orgRoleData) {
          if (responseData?.userOrgRoles[0]?.org?.activeSubscription) {
            updateOrgRoleData(responseData.userOrgRoles[0]);
            setHasAccess(true);
            return;
          } else {
            updateOrgRoleData(responseData.userOrgRoles[0]);
            setAccessAndNavigate(
              location.pathname === '/registration/subscription',
              '/registration/subscription',
            );
            return;
          }
        }

        // Has multipe organizations and the org has not been selected yet
        if (
          responseData.userOrgRoles.length > 1 &&
          location.pathname !== '/selectOrganization' &&
          !orgRoleData
        ) {
          setAccessAndNavigate(false, '/selectOrganization');
          return;
        }

        // Org has been selected and Has Organization Subscription
        if (hasSubscription) {
          updateOrgRoleData(orgRoleData);
          setHasAccess(true);
        } else {
          setAccessAndNavigate(
            location.pathname === '/registration/subscription',
            '/registration/subscription',
          );
        }
      } catch (e) {
        setLoading(false);
        setHasAccess(false);
        console.error(e);
      }
    };
    fetchData();
  }, []);

  if (loading)
    return <Loading message="Loading your organizational settings..." />;

  if (hasAccess === null) return null; // Loading or initial state
  if (hasAccess) return <Component />;
  return null;
};
