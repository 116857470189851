import { useState } from 'react';

export function useOrgVerificationCode() {
  const getInitialOrgVerificationCode = () => {
    const storedOrgVerificationCode = localStorage.getItem(
      'orgVerificationCode',
    );
    return storedOrgVerificationCode
      ? JSON.parse(storedOrgVerificationCode)
      : null;
  };

  const [orgVerificationCode, setOrgVerificationCode] = useState(
    getInitialOrgVerificationCode(),
  );

  const updateOrgVerificationCode = (newData) => {
    localStorage.removeItem('orgVerificationCode');
    localStorage.setItem('orgVerificationCode', JSON.stringify(newData));
    setOrgVerificationCode(newData);
  };

  const removeOrgVerificationCode = () => {
    localStorage.removeItem('orgVerificationCode');
    setOrgVerificationCode(null);
  };

  return {
    orgVerificationCode,
    updateOrgVerificationCode,
    removeOrgVerificationCode,
  };
}
